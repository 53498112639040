export const HEADER_TITLE:string = "Deutsche Splat Bundesliga e.V."
export const COPYRIGHT_YEAR:number = new Date().getFullYear()
export const COPYRIGHT_OWNER:string = "Deutsche Splat Bundesliga e.V."

export const COPYRIGHT = ():string => {
  return `© ${COPYRIGHT_YEAR} ${COPYRIGHT_OWNER}`
}

export const DISCORD_LINK:string = "https://discord.com/invite/pd46EXE"
export const YOUTUBE_LINK:string = "https://www.youtube.com/@DeutscheSplatoonBundesliga"
export const X_LINK:string = "https://x.com/DeutscheSplateV"
export const TWITCH_LINK:string = "https://twitch.tv/deutschesplbundesliga"
export const INSTAGRAM_LINK:string = "https://www.instagram.com/deutschesplatbundesliga/"
export const TIKTOK_LINK:string = "https://www.tiktok.com/@deutschesplatbundesliga"
export const TEAM_APPLICATION_MAIL_ADDRESS:string = "deutschesplatbundesliga.ev@gmail.com"