export const BASE_TITLE:string = "DSB"
export const SITE_HOME_URL:string = "/"
export const SITE_TEAM_URL:string = "/team"
export const SITE_SEASON_URL:string = "/season"
export const SITE_ARCHIVE_URL:string = "/archiv"
export const SITE_CALENDAR_URL:string = "/calendar"

export const SITE_IMPRINT_URL:string = "/about/imprint"
export const SITE_PRIVACY_URL:string = "/about/privacy"

export const IS_A_CURRENT_SEASON:boolean = false
export const CURRENT_SEASON_NAME:string = "DSB Season 8"
export const CURRENT_SEASON_DATE:string = "08.04. - 02.06.2024"
export const CURRENT_SEASON_TOURNAMENTS = [
    /*"0321f453-0221-4e64-932d-ac8aeb186a52",     //DSB-S6-D1
    "90052067-c7ac-4c10-83e6-c399677697df",     //DSB-S6-D2
    "d5f8534d-b29a-439c-9857-77a56d8e9752",     //DSB-S6-D3
    "5587a109-b7d0-4241-b290-7c21cd2c8ab5",     //DSB-S6-D4
    "3e810122-bc6d-4f45-b5bc-fd7f542a99ca",     //DSB-S6-D5
    "7822f6db-190f-4198-bff6-0466d90e279b",     //DSB-S6-D6
    "b17d86a8-4557-4aa1-b0ab-d9350bdd03d9",     //DSB-S6-D7
    "6e8cdd66-0b64-4180-be05-08ab78bb9310"      //DSB-S6-D8*/

    "0c7baf77-5fba-48c2-a971-b53efce72bd7",     //DSB-S7-D1
    "140b4bcd-0510-4637-97cb-99b9683f9b93",     //DSB-S7-D2
    "26ce4884-cbca-4911-b2cb-3e5904b1f206",     //DSB-S7-D3
    "ff73e8c6-e1e2-4c97-856a-fd93307b6903",     //DSB-S7-D4
    "51784449-aeaf-43ba-9b8d-56d7ac8b2835",     //DSB-S7-D5
    "79e5f14b-8b47-45c7-8266-0487f51a2b96",     //DSB-S7-D6
    "c508adc5-4e4c-47c2-bbad-c870dc412537",     //DSB-S7-D7
    "ab3891b0-3f0a-4cc4-b01f-a2a80451af63"      //DSB-S7-D8
]

export const DISCORD_NAME:string = "Deutsche Splat Bundesliga e.V."
export const TEAMS_LAST_SEASON:number = 80
export const PLAYERS_LAST_SEASON:number = 475
export const DISCORD_LOOKING_FOR_CHANNEL_PLAYER:string = "#looking4player"
export const DISCORD_LOOKING_FOR_CHANNEL_TEAM:string = "#looking4team"

//Show or hide banner on team site (searching new team members)
export const SHOW_TEAM_BANNER_WE_HIRE = true